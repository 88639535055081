import React from "react";
import Layout from "../components/layout";
import {BLOCKS, MARKS} from "@contentful/rich-text-types"
import {renderRichText} from "gatsby-source-contentful/rich-text"
import BlogContactCard from "../components/blog/blog-contact-us";
import RecentPost from "../components/blog/recent-post";
import BlogCategories from "../components/blog/categories-post";
import {graphql} from "gatsby";
import get from "lodash/get";
import ReferencePost from "../components/blog/reference-post";
import SEO from "../components/seo";
import {GoBack} from "../components/go-back";
import Form from "../components/form";

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <strong>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      return (node.data.target.fluid?.src ?
        <>
            <div className='blog-image'>
              <img src={node.data.target.fluid?.src} alt=""/>
            </div>
        </> : <></>
      )
    },
  },
}

const Blog = (props) => {
  const info = props.pageContext.info
  const referenceBlogs = props.pageContext.referenceBlogs
  const recentPosts = get(props.data, 'allContentfulRecentPost.nodes')
  const lang = info.node_locale.slice(0, 2)

  return (
    <Layout location={props.location}>
      <SEO keywords={info.keywords} title={info.title} />
      <div className="container w-75 p-4">
        <div>
          <GoBack />
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="h2 py-4">
              {info.title && info.title}
            </div>
            <div className="text-muted">
              Created At: {info.createdAt.slice(0, 10)}
            </div>
            <div className="pt-5 pb-5 blog-content">
              {renderRichText(info.body, options)}
            </div>
          </div>
          <div className="col-md-3 ps-4">
            <BlogContactCard/>
            <ReferencePost posts={referenceBlogs}/>
            <RecentPost posts={recentPosts}/>
            <BlogCategories/>
          </div>
        </div>
        <div>
          <Form title={lang ==='ar'?  'اتصل بنا' : 'Contact us'} lang={lang} fullwidth/>
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
    query BlogQuery {
        allContentfulRecentPost(filter: {blog: {node_locale: {eq: "en-US"}}}) {
            nodes {
                blog {
                    id
                    node_locale
                    title
                    urlName
                    updatedAt
                }
            }
        }
    }
`


