import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import * as styles from "./blog.module.css"

const ReferencePost = ({posts, lang}) => {
  return posts && posts.length > 0 ? (
    <div className={styles.recent}>
      <h5>Reference Post</h5>
      <div>
        <ul>
          {
            posts.map(x => (
              <li key={x.id}>
                <AniLink to={`${lang ?? ''}/blogs/${x.urlName}`} duration={0.3} hex="#9dc428" paintDrip>
                  <span className='sub-color'>
                  {x.title}
                  </span>
                </AniLink>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  ) : null
}

export default ReferencePost
